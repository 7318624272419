import React, { Component } from 'react'
import { graphql } from 'gatsby'
import {get,first} from 'lodash'
import Layout from '../components/layout'
import { getDataForLanguage } from '../utils/getDataForLanguage'
import Paragraphs from '../components/helpers/paragraphs';

export class BasicPage extends Component {
    render() {
        const allPageData = get(this, "props.data.allContentfulBasicPage.edges", '');
        let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));

        return (
            <Layout 
                title={get(pageData, 'metaTitle','')} 
                description={get(pageData, 'metaDescription','')}
                pathName={get(pageData, 'slug','')}
            >
                <div className="basic-page">
                    <div className="container" id="maincontent">
                        <h1 className="page-title text-center">{get(pageData, 'title', '')}</h1>
                        <div className="basic-page__text">
                            <Paragraphs value={get(pageData,'mainText','')} />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default BasicPage

export const pageQuery = graphql`
  query BasicPageQuery($id: String!) {
    allContentfulBasicPage(filter: {id: {eq: $id}}) {
        edges {
            node {
                id
                title
                metaTitle
                metaDescription
                mainText {
                    mainText
                }
                slug
            }
        }
    }
  }
`